import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Journal from './pages/Journal';
import ChatBox from './components/ChatBox';
import ChatSidebar from './pages/ChatSidebar';
import AuthLanding from './auth/AuthLanding';
import SignupOptions from './auth/SignupOptions';
import EmailSignup from './auth/EmailSignup';
import EmailLogin from './auth/EmailLogin';
import ForgotPassword from './auth/ForgotPassword';
import NicknamePrompt from './auth/NicknamePrompt';
import Settings from './pages/Settings';
import { ThemeProvider } from './context/ThemeContext';
import { UserProvider, useUser } from './context/UserContext';

const AppWrapper = () => {
  const { user, loading } = useUser();

  const moodOptions = [
    { emoji: '😁', label: 'Excited' },
    { emoji: '😊', label: 'Happy' },
    { emoji: '😐', label: 'Neutral' },
    { emoji: '😢', label: 'Sad' },
    { emoji: '😠', label: 'Angry' },
  ];

  const [sessions, setSessions] = useState(() => {
    const saved = localStorage.getItem('chatSessions');
    return saved ? JSON.parse(saved) : [];
  });

  const [currentSessionId, setCurrentSessionId] = useState(() => {
    const saved = localStorage.getItem('currentSessionId');
    const all = JSON.parse(localStorage.getItem('chatSessions') || '[]');
    const isValid = all.some((s) => s.id.toString() === saved);
    return isValid ? saved : null;
  });

  const [selectedMood, setSelectedMood] = useState(null);

  const currentSession = sessions.find((s) => s.id.toString() === currentSessionId);

  useEffect(() => {
    localStorage.setItem('chatSessions', JSON.stringify(sessions));
  }, [sessions]);

  useEffect(() => {
    if (currentSessionId) {
      localStorage.setItem('currentSessionId', currentSessionId);
    }
  }, [currentSessionId]);

  if (loading) return null;
  const isAuthenticated = !!user;

  return (
    <ThemeProvider>
      <div className="flex flex-col h-screen text-[18px]">
        <Navbar username={user?.displayName || 'User'} />
        <div className="flex flex-1 overflow-hidden">
          {isAuthenticated && (
            <ChatSidebar
              sessions={sessions}
              currentSessionId={currentSessionId}
              onNewChat={() => {
                const newId = Date.now();
                setCurrentSessionId(newId);
                setSelectedMood(null); // show mood popup
              }}
              onSelectSession={setCurrentSessionId}
              onDeleteSession={(id) => {
                const updated = sessions.filter((s) => s.id !== id);
                setSessions(updated);
                localStorage.removeItem(`chat-${id}`);
                localStorage.removeItem(`mood-${id}`);
                localStorage.removeItem(`lang-${id}`);
                if (id.toString() === currentSessionId) {
                  setCurrentSessionId(updated.length ? updated[0].id : null);
                }
              }}
              onRenameSession={(id, newName) => {
                const updated = sessions.map((s) =>
                  s.id === id ? { ...s, name: newName } : s
                );
                setSessions(updated);
              }}
            />
          )}
          <div className="flex-1 overflow-auto">
            <Routes>
              <Route path="/" element={isAuthenticated ? <Home /> : <Navigate to="/auth" />} />
              <Route path="/journal" element={isAuthenticated ? <Journal /> : <Navigate to="/auth" />} />
              <Route path="/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/auth" />} />
              <Route
                path="/chat"
                element={
                  isAuthenticated ? (
                    currentSessionId ? (
                      <ChatBox
                        key={currentSessionId}
                        sessionId={currentSessionId}
                        selectedMood={selectedMood}
                        onMoodSelect={setSelectedMood}
                        onUpdateSessions={(updatedMessages) => {
                          const mood = localStorage.getItem(`mood-${currentSessionId}`) || '🧠';
                          const moodLabel = moodOptions.find((m) => m.emoji === mood)?.label || 'Mood';
                          const currentTime = new Date().toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          });

                          const sessionExists = sessions.some((s) => s.id.toString() === currentSessionId.toString());

                          let updated;
                          if (sessionExists) {
                            updated = sessions.map((s) =>
                              s.id.toString() === currentSessionId.toString()
                                ? { ...s, messages: updatedMessages }
                                : s
                            );
                          } else {
                            const newSession = {
                              id: currentSessionId,
                              name: `${mood} ${moodLabel} • ${currentTime}`,
                              timestamp: new Date().toLocaleDateString(),
                              messages: updatedMessages,
                            };
                            updated = [newSession, ...sessions];
                          }

                          setSessions(updated);
                        }}
                        initialMessages={
                          currentSession?.messages?.length ? currentSession.messages : []
                        }
                      />
                    ) : (
                      <div className="flex items-center justify-center h-full text-lg text-gray-500">
                        Start a new chat to begin talking with Mitra.
                      </div>
                    )
                  ) : (
                    <Navigate to="/auth" />
                  )
                }
              />
              <Route path="/auth" element={<AuthLanding />} />
              <Route path="/signup-options" element={<SignupOptions />} />
              <Route path="/signup-email" element={<EmailSignup />} />
              <Route path="/login-email" element={<EmailLogin />} />
              <Route path="/nickname" element={<NicknamePrompt />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const App = () => (
  <UserProvider>
    <AppWrapper />
  </UserProvider>
);

export default App;
