import React, { useState } from 'react';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  signOut,
  getAdditionalUserInfo,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';

const AuthLanding = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');

  const navigate = useNavigate();

  const handleLogin = async () => {
    setError('');
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.includes('google.com')) {
        setError('You signed up using Google. Please log in with Google instead.');
        return;
      }
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
    } catch (err) {
      console.error('Login error:', err);
      setError('Username/Password does not match.');
    }
  };

  const handleGoogleLogin = async () => {
    setError('');
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const email = user.email;
      const isNewUser = getAdditionalUserInfo(result)?.isNewUser;

      if (isNewUser) {
        await setDoc(doc(db, 'users', user.uid), {
          email: user.email,
          name: user.displayName,
          createdAt: serverTimestamp(),
        });
      }

      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (
        methods.length === 0 ||
        (methods.length === 1 && methods[0] === 'google.com') ||
        (methods.includes('google.com') && methods.includes('password'))
      ) {
        navigate('/');
      } else {
        setError('Please sign up with Google first.');
        await signOut(auth);
      }
    } catch (err) {
      console.error('Google login error:', err);
      setError('Google login failed. Please try again.');
    }
  };

  const handlePasswordReset = async () => {
    setResetMessage('');
    setError('');
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Reset link sent to your email.');
    } catch (err) {
      setError('Failed to send reset email.');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen + bg-gradient-to-b from-[#e6fffa] to-[#f9fafb] text-center px-6">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md text-center">
        <h2 className="text-3xl font-semibold mb-6">
		  Welcome to{' '}
		  <span className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#a855f7] to-[#5eead4]">
			WellMitra
		  </span>
		</h2>


        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}

        <input
          type="email"
          className="w-full mb-3 p-2 border rounded-md bg-blue-50"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            className="w-full mb-3 p-2 border rounded-md bg-blue-50 pr-10"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span
            className="absolute inset-y-0 right-3 flex items-center text-gray-600 cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <EyeOff size={18} /> : <Eye size={18} />}
          </span>
        </div>

        <button
          onClick={handleLogin}
          className="w-full max-w-sm bg-gradient-to-r from-[#f3c7f7] to-[#e0dfff] text-[#6b21a8] py-3 rounded-lg mb-4 shadow-md transition-transform duration-300 hover:scale-105"
        >
          Log In
        </button>

        <p className="text-base text-gray-500 my-2">or</p>

        <button
          onClick={handleGoogleLogin}
          className="w-full max-w-sm bg-gradient-to-br from-[#5eead4] via-[#a7f3d0] to-[#f3e8ff] text-[#0f172a] py-3 rounded-lg mb-6 shadow-md transition-transform duration-300 hover:scale-105"
        >
          Sign in with Google
        </button>

        <button
          onClick={() => setShowForgot(!showForgot)}
          className="text-sm text-purple-600 hover:underline mt-4"
        >
          Forgot Password?
        </button>

        {showForgot && (
          <>
            <input
              type="email"
              placeholder="Enter your email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              className="w-full p-2 mt-3 border rounded-md"
            />
            <button
              onClick={handlePasswordReset}
              className="w-full mt-2 bg-green-500 hover:bg-green-600 text-white py-2 rounded-md"
            >
              Send Reset Link
            </button>
            {resetMessage && (
              <p className="text-green-600 text-sm mt-2">{resetMessage}</p>
            )}
          </>
        )}

        <p className="mt-6 text-sm text-black">
          Don’t have an account?{' '}
          <Link to="/signup-options" className="text-purple-600 hover:underline">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default AuthLanding;
