import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '../context/UserContext';

const Navbar = () => {
  const { user } = useUser();
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const nickname = localStorage.getItem('nickname');
    if (nickname) {
      setDisplayName(nickname);
    } else if (user?.displayName) {
      setDisplayName(user.displayName);
    } else if (user?.email) {
      setDisplayName(user.email);
    }
  }, [user]);

  return (
    <nav className="flex justify-between items-center py-4 bg-white shadow-md">
      <Link to="/" className="flex items-center gap-2">
        <img src="https://img.icons8.com/fluency/48/lotus.png" alt="MitraAI Logo" className="w-8 h-8" />
        <span className="text-xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#a855f7] to-[#5eead4]">WellMitra</span>
      </Link>
      <div className="flex items-center gap-3">
        <Link to="/" className="px-3 py-1 bg-white border border-[#a855f7] rounded-full text-[#a855f7] hover:bg-[#f3e8ff] hover:ring-2 hover:ring-[#a855f7] transition duration-200">Home</Link>
        <Link to="/journal" className="px-3 py-1 bg-white border border-[#a855f7] rounded-full text-[#a855f7] hover:bg-[#f3e8ff] hover:ring-2 hover:ring-[#a855f7] transition duration-200">Journal</Link>
        <Link to="/chat" className="px-3 py-1 bg-white border border-[#a855f7] rounded-full text-[#a855f7] hover:bg-[#f3e8ff] hover:ring-2 hover:ring-[#a855f7] transition duration-200">Talk to Mitra</Link>
        <Link to="/settings" className="px-3 py-1 bg-white border border-[#a855f7] rounded-full text-[#a855f7] hover:bg-[#f3e8ff] hover:ring-2 hover:ring-[#a855f7] transition duration-200">Settings</Link>
        <span className="text-purple-700 font-medium mr-4">Welcome {displayName}</span>
      </div>
    </nav>
  );
};

export default Navbar;